@import "../assets/styles/mixins";



.nv-main {
  width: 100%;

  .navbar-main {
    width: 100%;
    height: 120px;
    background-color: white;
    padding: 16px 30px;
    margin: auto;
    font-size: 16px;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important;
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: black !important;
      font-size: 16px;
    }

    @include mobile {
      padding: 0 1rem;
      height: 60px;
      border-bottom: 1px solid rgba(128, 128, 128, 0.123);
    }

    @include tablet {
      padding: 0 1rem;
      height: 60px;
      border-bottom: 1px solid rgba(128, 128, 128, 0.123);
    }

    .pg-title {
      width: 50%;
      position: relative;
      gap: 5px;

      @include mobile {
        width: 20%;
      }

      @include tablet {
        width: 20%;
      }

      h1 {
        font-weight: 600;
        z-index: 10;
      }

      button {
        background-color: unset;
      }

      .back-button {
        width: 52px;
        height: 42px;
        left: -55px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .nv-profile {
      width: 50%;

      @include mobile {
        width: 80%;
      }

      @include tablet {
        width: 80%;
      }

      .avatar {
        width: 45px;
        height: 45px;
        border-radius: 20px;
        position: relative;

        @include mobile {
          width: 28px;
          height: 28px;
          border-radius: 4px;
        }

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }
      }

      .p-infos {
        // padding: 0 15px;
        // min-width: 120px;
        // width: unset;

        h3 {
          font-size: 14px;
          text-align: left;
          font-weight: 600;
          margin-right: 14px;
          margin-left: 16px;
        }

        p {
          font-size: 10px;
          font-weight: 600;
          color: #f2c94c;
        }

        .profile-menu-button {
          background: none;
          position: relative;
          z-index: 2;
          padding: 0;
          margin-left: 18px;
          gap: 18px;

          .display-name {
            font-size: 16px;
            margin: 0;
            padding: 0;
            letter-spacing: 0px;
          }

          &:hover {
            transform: none;
          }
        }

        .profile-menu {
          position: absolute;
          min-width: 290px;
          min-height: 208px;
          padding: 10px;
          background: white;
          border-radius: 30px;
          right: 0;
          transform: translate(0, 65%);
          box-shadow: 4px 22px 54px rgb(105 95 151 / 10%);

          .menu-item {
            width: 100%;
            border-radius: 14px;
            padding: 10px 20px;
            height: 40px;
            background-color: transparent;

            &:hover {
              background-color: #FFDA4841;
            }

            .menu-icon {
              margin-right: 10px;
              width: 15px;

              svg {
                height: 100%;
                filter: invert(0%) sepia(5%) saturate(20%) hue-rotate(146deg) brightness(87%) contrast(105%);
              }
            }

            p {
              color: #5a6276;
              font-size: 14px;
              text-align: left;
            }
          }

          .divider {
            width: 100%;
            margin: 10px 10px;
            height: 1px;
            background-color: #ebebeb;
          }
        }
      }
    }
  }
}

.sd-opened {
  transition: 0.5s;
  left: 0;
}

.sd-closed {
  transition: 0.5s;
  left: -100%;
}

.sd-main {
  width: 300px;
  height: 100%;
  background-color: #ffffff;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  padding: 40px 20px;
  flex-shrink: 0;

  @include mobile {
    position: fixed;
    width: 75%;
    max-width: unset;
    z-index: 200000000000;
    overflow-y: auto;
    height: 100%;
  }

  @include tablet {
    position: fixed;
    z-index: 20;
    width: 75%;
    max-width: 300px;
  }

  .sd-logo {
    width: 100%;
    margin-bottom: 40px;
    padding: 0 20px;
    position: relative;

    img {
      height: 40px;
    }

    .close {
      position: absolute;
      right: 1rem;
    }
  }

  .adv {
    width: calc(100% - 40px);
    border-radius: 20px;
    color: white;
    position: relative;
    overflow: hidden;
    padding: 0;

    .banner-content {
      position: absolute;
      top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      p.title {
        font-size: 14px;
        text-align: center;
        font-weight: 500;
      }

      .description {
        font-size: 10px;
        font-weight: 400;
      }

      .ad-learn-more-button {
        width: 80%;
        align-self: center;
        justify-self: center;
        height: 38px;
        background-color: #98FF98;
        color: black;
        border-bottom: none;
        font-size: 11px;
        font-weight: bold;
        border-radius: 10px;
      }
    }

    .screen-shot {
      width: calc(100% + 20px);
      object-fit: fill;
    }

  }

  & .ext {
    margin-top: 30px;
  }

  ul {
    width: 100%;

    li {
      width: calc(100% - 20px);
      height: 40px;
      cursor: pointer;
      padding: 0 15px;
      margin: 1px 0;
      border-radius: 15px;

      .dp-m {
        margin-left: 10px;
      }

      h5 {
        padding-left: 10px;
        font-weight: 400;
        color: #5a6276;
      }

      span {
        svg {
          width: 18px;
        }
      }
    }

    .sub-menu {
      background-color: #fafafa;
      width: 100%;

      .sub {
        width: calc(100% - 20px);
        padding-left: 30px;
        border: 1px solid #fafafa;
        margin: 0;
      }
    }

    li:hover {
      background-image: var(--green-gradient);
      //width: 100%;

      h5 {
        color: white;
      }

      span {
        svg {
          path {
            fill: white;
          }
        }
      }
    }

    & .active {
      background-image: var(--green-gradient);
      //width: 100%;

      h5 {
        color: white;
      }

      span {
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
}

.layout {
  width: 100vw;
  height: 100vh;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  //  max-width: 1920px;

  .layout-main {
    min-width: calc(100% - 300px);
    height: 100%;
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;

    .app-container-main {
      overflow: visible;
    }

    @include mobile {
      width: 100%;
    }

    @include tablet {
      width: 100%;
    }
  }
}