@import './_mixins.scss';

h1 {
  font-size: 32px;
  font-family: 'Open Sans', sans-serif;
}

h2 {
  font-size: 22px;
  font-family: 'Open Sans', sans-serif;
}

h3 {
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
}

h4 {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}

h5 {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
}

p {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
}

.f30 {
  font-size: 30px;
  font-family: 'Open Sans', sans-serif;
}

.f26 {
  font-size: 26px;
  font-family: 'Open Sans', sans-serif;
}

.f24 {
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
}

.f50 {
  font-size: 40px;
  font-family: 'Open Sans', sans-serif;
}

@include mobile-tablet {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 13px;
  }

  .f30 {
    font-size: 24px;
  }

  .f26 {
    font-size: 20px;
  }

  .f24 {
    font-size: 22px;
  }

  .f50 {
    font-size: 40px;
  }
}

@include desktop-md {
  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 17px;
  }

  h4 {
    font-size: 15px;
  }

  h5 {
    font-size: 14px;
  }

  .f30 {
    font-size: 25px;
  }

  .f26 {
    font-size: 23px;
  }

  .f24 {
    font-size: 22px;
  }

  .f50 {
    font-size: 41.67px;
  }
}

@include desktop-sm {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 19px;
  }

  h3 {
    font-size: 17px;
  }

  h4 {
    font-size: 15px;
  }

  h5 {
    font-size: 14px;
  }

  .f30 {
    font-size: 24px;
  }

  .f26 {
    font-size: 22px;
  }

  .f24 {
    font-size: 21px;
  }

  .f50 {
    font-size: 40px;
  }
}

@include desktop-xs {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 13px;
  }

  .f30 {
    font-size: 22px;
  }

  .f26 {
    font-size: 20px;
  }

  .f24 {
    font-size: 20px;
  }

  .f50 {
    font-size: 36.67px;
  }
}

.bold {
  font-weight: 600;
}

.sbold {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

a {
  text-decoration: none;
  cursor: poProximaNova;

  &:visited {
    text-decoration: none;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }
}

select {
  resize: none;
  background: none;
  outline: none;
  border: none;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}