@import '../../../assets/styles/mixins';

.banner {
    margin-top: 40px;
    width: calc(100% - 50px);
    min-height: 200px;
    background: transparent linear-gradient(90deg, #333333 0%, #07A042 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin-left: 40px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;

    div.flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        position: absolute;
        width: 100%;
        padding: 0px 40px;

        @include mobile {
            flex-direction: column;
            padding: 30px 20px;
        }

        .banner-content-holder {
            display: flex;
            flex-direction: column;
            gap: 10px;

            @include mobile {
                align-items: center;
            }
        }

        .banner-title {
            font-weight: bold;
            font-size: 28px;
            color: white;

            @include mobile {
                text-align: center;
            }
        }

        .banner-subtitle {
            font-weight: 400;
            font-size: 18px;
            color: white;

            @include mobile {
                text-align: center;
            }
        }

        .banner-btn {
            background: #98FF98 0% 0% no-repeat padding-box;
            border-radius: 10px;
            color: black;
            font-weight: 500;
            font-size: 14px;
            padding: 10px 20px;
            width: fit-content;
        }
    }

    @include mobile-tablet {
        width: calc(100% - 80px);
        margin-left: 40px;
        margin-right: 40px;
    }

    @include mobile {
        min-height: 360px;
    }

    .faq-icon {
        width: 120px;
    }
}

.support-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 80px 40px 40px 40px;
    max-width: 1280px;

    @include mobile-tablet {
        flex-direction: column;
        padding: 40px;
    }

    .header-container {
        width: 100%;
        gap: 5px;
        flex: 1;

        .title {
            font-weight: bold;
            font-size: 40px;

            @include mobile-tablet {
                font-size: 20px;
            }
        }

        .subTitle {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .form {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 42px;

        .support-input {
            width: 100%;
            border: 0;
            position: relative;

            .support-input-label {
                background: transparent linear-gradient(90deg, #70BD22 0%, #3AB453 100%) 0% 0% no-repeat padding-box;
                border-radius: 9px;
                padding: 10px 20px;
                position: absolute;
                top: 0px;
                left: 10px;
                transform: translateY(-50%);
                z-index: 10;
                color: white;
                font-weight: 600;
                font-size: 14px;
            }

            input.support-input {
                width: 100%;
                height: 60px;
                border: 1px solid;
                border-radius: 11px;
                border-color: #C3C3C3;
                padding: 12px 16px 0 16px;
                font-size: 14px;
                font-weight: '500';
            }

            input::placeholder {
                color: #C1C1C1;
                opacity: 1;
                font-size: 14px;
                font-weight: '500';
            }

            input.error {
                margin-bottom: 6px;
                border-color: red;
            }

            textarea.support-input {
                width: 100%;
                height: 220px;
                border: 1px solid;
                border-radius: 11px;
                border-color: #C3C3C3;
                padding: 40px 16px 0 16px;
                font-size: 14px;
                font-weight: '500';
            }

            textarea::placeholder {
                color: #C1C1C1;
                opacity: 1;
                font-size: 14px;
                font-weight: '500';
            }

            textarea.error {
                margin-bottom: 6px;
                border-color: red;
            }

            .error-message {
                background-color: white;
                border-color: red;
                border: 1px solid;
                padding: 6px 20px;
                border-radius: 11px;
                position: absolute;
                bottom: 0;
                left: 10px;
                transform: translateY(60%);
                color: red;
                font-size: 12px;
            }
        }

        .submit-btn {
            background: transparent linear-gradient(96deg, #71BD22 0%, #00AB89 100%) 0% 0% no-repeat padding-box;
            box-shadow: 1px 8px 29px #17DF7824;
            border-radius: 10px;
            height: 44px;
            color: white;
            font-weight: bold;
            font-size: 14px;
        }

    }

}