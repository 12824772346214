:root {
  --white: #ffffff;
  --black: #000000;
  --red: #d0021b;
  --orange: #ff6100;
  --dark: #0f1721;
  --grey-dark: #5A6276;
  --grey: #ACB3BA;
  --grey-light: #F2F6FB;
  --light-green: #71BD22;
  --dark-green: #02A383;
  --blue: #578DF5;
  --green-gradient: linear-gradient(136.05deg, #71BD22 12.16%, #02A383 86.37%);
  --red-gradient: linear-gradient(136.05deg, #ed4747 12.16%, #e30b0b 86.37%);
  --button-green-gradient: linear-gradient(90deg, #71bd22 0, #00ab89 25%, #71bd22 75%, #00ab89);
  --essential-gradient: linear-gradient(63deg, #B34126 0%, #E69742 100%);
  --manager-gradient: linear-gradient(63deg, #421871 0%, #68257E 100%);
  --pro-gradient: linear-gradient(63deg, #A82624 0%, #D5514B 100%);
  --scan-gradient: linear-gradient(63deg, #0B2555 0%, #0B3286 100%);
  --cuntrion-gradient: linear-gradient(63deg, #070707 0%, #424242 100%);
  --br-inputs: 12px;
  --br-circle: 50%;
  --box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.05);
  font-size: 16px;
  --challenges-gold: #FFC12D;
  --grey-blue: #7A86A1;
}